import React,{useState} from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
// import Text from '../../../components/ui/text'
import Modal from 'react-bootstrap/Modal'
import Button from '../../../components/ui/button'
import { SectionWrap, JobList, JobItem, JobTitle, JobType, JobDesc, JobButton } from './style'
import ApplicationForm from '../../../components/forms/job-form'

const JobsArea = () => {
    // {textStyle}
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const [handleCloseModal, setHandleCloseModal] =useState(false)
    const handleShow = () => setShow(true);

    const getJobsData = useStaticQuery(graphql`
    query GET_JOB_DESCRIPTION_DATA {

        descriptionintro: jobdescriptionJson(id: {eq: "job-description-section"}) {
            title
            dateposted
            intro
        }
        yourroles: jobdescriptionJson(id: {eq: "your-roles"}) {
            roleintro
            roles {
                id
                role
            }
        }
        whatyoubring: jobdescriptionJson(id: {eq: "what-you-bring"}) {
            whatyoubringintro
            whatyoubring {
                id
                title
            }
        }
        howwework: jobdescriptionJson(id: {eq: "how-we-work"}) {
            howweworkintro
            howwework {
                id
                title
            }
        }
    }      
`);


const { descriptionintro: { title, dateposted, intro}, yourroles:{roleintro, roles}, whatyoubring:{ wahtyoubringintro,wahtyoubring}, howwework:{howweworkintro,howwework }} = getJobsData
return (
    <SectionWrap>
        <Container>
            <Row justify="center">
                <Col lg={7}>
                    <SectionTitle title={title} />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                <Row>

                    <Col lg={3}>
                        <JobTitle>{title}</JobTitle>
                        <JobType>{dateposted}</JobType>
                    </Col>

                    <Col lg={6}>
                        <JobDesc>{intro}</JobDesc>
                    </Col>

                    <Col lg={3}>
                        <JobButton>
                            <Button onClick={handleShow} varient="outlined" bordercolor="#ddd">Get Started</Button>
                        </JobButton>
                    </Col>
                </Row>

                    <JobList>
                        {/* {descriptionintro.map((desc, i) => {
                            return (
                                <JobItem key={desc.id} >
                                    <Row>
                                        <Col lg={3}>
                                            <JobTitle>{desc.title}</JobTitle>
                                            <JobType>{desc.dateposted}</JobType>
                                        </Col>
                                        <Col lg={6}>
                                            <JobDesc>{desc.intro}</JobDesc>
                                        </Col>

                                        <Col lg={3}>
                                            <JobButton>
                                                <Button onClick={handleShow} varient="outlined" bordercolor="#ddd">Get Started</Button>
                                            </JobButton>
                                        </Col>
                                    </Row>
                                </JobItem>
                            )
                        })} */}
                    </JobList>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}  size="xl">
                <Modal.Header closeButton>
                <Modal.Title>Apply Online</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApplicationForm/>
                </Modal.Body>
            </Modal>
        </Container>
    </SectionWrap>
)
}

export default JobsArea
